import React from "react"
import Layout from "../../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col, Nav } from "react-bootstrap"

const Recent = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={10}>
              <h1 className="page-title">Publications</h1>
              <div className="publication-section">
                <Nav variant="tabs" defaultActiveKey="/publications/recent">
                  <Nav.Item>
                    <Nav.Link href="/publications/recent">Recent</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/articles">Articles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/chapters">Chapters</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/patents">Patents</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/all">All</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <p className="resume-text">
                <span className="bold author">CHAPTERS</span>
              </p>
              <div className="publication-unit-first">
                <p className="publication-title">
                  [03] Isolation of Labrenzia alexandrii Strains Producing a
                  Pederin-Like Compound Using a Dilution to Extinction
                  Methodology in 384 Microplate Format
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> X. Benitez, J.
                  Garcia, <span className="author">E. G. Gonzalez,</span> F. de
                  la Calle
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in&nbsp;
                  <span className="italic">Marine Drugs,</span> 1:8. MDPI,
                  Switzerland
                </p>
                <p className="publication-link">
                  <a href="https://www.mdpi.com/1660-3397/18/1/40/htm">
                    <i class="bi bi-link"></i> Link to Publication
                  </a>
                </p>
              </div>

              <p className="resume-text">
                <span className="bold author">ARTICLES</span>
              </p>

              <div className="publication-unit">
                <p className="publication-title">
                  [34] Detection of a pederin-like compound using a Dilution to
                  Extinction (DTE) based platform for the isolation of marine
                  bacteria in drug discovery strategies
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> X. Benitez, J.
                  Garcia, P. Zuñiga,&nbsp;
                  <span className="author">E. G. Gonzalez,</span> F. de la Calle
                  &amp; C. Cueva
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in&nbsp;
                  <span className="italic">Microbial Biotechnology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>&nbsp;
                  10.1111/1751-7915.13679
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [33] Influence of historical and human factors on genetic
                  structure and diversity patterns in peripheral populations:
                  implications for the conservation of Moroccan trout
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> S. Perea, M. Al
                  Mouri, <span className="author">E. G. Gonzalez,</span> L.
                  Alcaraz, A. Yahyaoui &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in&nbsp;
                  <span className="italic">BioRxiv</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>&nbsp;
                  10.1101/2020.04.06.027219
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [32] Lack of genetic differentiation between two sympatric
                  species of <span className="bold-italic">Astyanax</span>&nbsp;
                  (Characidae:Teleostei) in Lake Catemaco, Mexico
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> P. Ornelas,&nbsp;
                  <span className="author">E. G. Gonzalez,</span> D. Tautz &amp;
                  I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in&nbsp;
                  <span className="italic">Resaearch Square</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>&nbsp;
                  10.21203/rs.2.22839/v1
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Recent
